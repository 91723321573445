import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { mq, colors } from "../utils/presets"
import Img from "gatsby-image/withIEPolyfill"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/pro-light-svg-icons"

const Carditem = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
`

const Card = styled.div`
  /* min-width: 280px; */
  /* max-width: 360px; */
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  margin: 1rem;
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
  width: 100%;
  ${mq.tablet} {
    width: 45%;
    flex: 0 1 auto;
  }
  ${mq.desktop} {
    width: 20%;
  }
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    flex: 0 1 auto;
  }
  .entry {
    text-align: center;
    flex: 0 1 auto;
    h3 {
      margin-top: 1.2rem;
      margin-bottom: 0.5rem;
    }
  }
  .preis {
    p {
      margin-top: 0;
      text-align: center;
      font-weight: bold;
      font-size: 1.8rem;
      color: ${colors.black};
    }
  }
  .sizes {
    background: #fcfcfc;
    ${mq.mobile} {
      ul {
        li {
          padding-right: 5% !important;
        }
      }
    }
    ul {
      list-style: none;
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      margin: 0;
      padding: 0;
      align-items: flex-end;
      border-top: 1px solid #aaa;
      text-align: right;
      margin-bottom: 0;
      li {
        width: 50%;
        flex: 1 0 auto;
        padding: 0.5rem;
        padding-bottom: 0;
        margin-bottom: 0;
        font-weight: bold;
        border-bottom: 1px solid #aaa;
        img {
          width: 40px;
          height: auto;
        }
        .klein {
          width: 50px;
        }
        .mittel {
          width: 60px;
        }
        .gross {
          width: 70px;
        }
      }
    }
  }
  .desc {
    padding: 1rem;
    flex: 1 0 auto;
    width: 100%;
  }
  .btnwrap {
    flex: 0 1 auto;
    text-align: center;
    padding-bottom: 2rem;
  }
  .wrapper {
    padding: 1rem;
    flex: 1 0 auto;
    padding-bottom: 0;
    ul {
      margin-bottom: 0.7rem;
    }
  }
`

const Button = styled.a`
  /* This renders the buttons above... Edit me! */
  display: inline-block;
  padding: 0.5rem 0;
  margin: 0 auto;
  width: 70%;
  border-radius: 20px;
  background: ${colors.black};
  color: white;
  font-family: "Open Sans Condensed";
  font-weight: 400;
  text-align: center;
  font-size: 1.2rem;
  text-decoration: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  &:hover,
  &:focus {
    opacity: 0.7;
  }
`

const Dogimage = styled(Img)`
  width: 100%;
  flex: 0 1 auto;
  ${mq.tablet} {
    aspect-ratio: 4/3;
    /* height: 280px; */
    width: 100%;
    flex: 0 1 auto;
  }
`

const Zusatzpakete = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressPage(slug: { regex: "/leistung/" }) {
          acf {
            Zusatzpakete_page {
              bild {
                localFile {
                  childImageSharp {
                    sizes(maxWidth: 400) {
                      ...GatsbyImageSharpSizes
                    }
                  }
                }
              }
              titel
              text
              Preis
            }
          }
        }
      }
    `}
    render={data => (
      <Carditem>
        {data.wordpressPage.acf.Zusatzpakete_page.map(item => (
          <Card>
            <Dogimage
              objectFit="cover"
              objectPosition="50% 50%"
              sizes={item.bild.localFile.childImageSharp.sizes}
              alt={item.title}
            />
            <div className="entry">
              <h3>{item.titel}</h3>
            </div>
            <div
              className="wrapper"
              dangerouslySetInnerHTML={{ __html: item.text }}
            />
            <div className="preis">
              <p>{item.Preis} €</p>
            </div>
            <div className="btnwrap">
              <Button href="tel:+4917697722551">
                <FontAwesomeIcon style={{ marginRight: 10 }} icon={faPhone} />{" "}
                Termin vereinbaren
              </Button>
            </div>
          </Card>
        ))}
      </Carditem>
    )}
  />
)

export default Zusatzpakete
