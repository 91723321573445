import styled from "styled-components"
import { colors } from "../utils/presets"

const Button = styled.a`
  display: inline-block;
  padding: 0.5rem 1rem;
  background: ${colors.black};
  color: white;
  font-family: "Open Sans";
  font-weight: 400;
  text-align: center;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 20px;
`
export default Button
