import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { mq, colors } from "../utils/presets"
import mini from "../images/leistungen/mini.svg"
import klein from "../images/leistungen/klein.svg"
import mittel from "../images/leistungen/mittel.svg"
import gross from "../images/leistungen/gross.svg"

const Hundeliste = styled.div`
  margin: 2rem 1rem;
  border-top: 2px solid ${colors.black};
  border-bottom: 2px solid ${colors.black};
`

const HundeInside = styled.div`
  max-width: 1600px;
  padding-top: 1rem;
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
`

const Hund = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-flow: row;
  width: 100%;
  ${mq.tablet} {
    width: 50%;
  }
  ${mq.xl} {
    width: 25%;
  }
  .imgwrapper {
    width: 80px;
    flex: 1 0 auto;
    img {
      flex: 0 1 auto;
      width: 40px;
      height: auto;
    }
    .klein {
      width: 50px;
    }
    .mittel {
      width: 60px;
    }
    .gross {
      width: 70px;
    }
  }
  .textwrap {
    width: 90%;
    padding-right: 1rem;
    padding-left: 0.8rem;
    h2 {
      font-size: 0.9rem;
      margin-bottom: 0.3rem;
    }
    p {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }
  }
`

const Hundegroessen = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressPage(slug: { regex: "/leistung/" }) {
          acf {
            hundegroessen_page {
              bild {
                url {
                  source_url
                }
              }
              titel
              text
            }
          }
        }
      }
    `}
    render={data => (
      <Hundeliste>
        <HundeInside>
          <Hund>
            <div className="imgwrapper">
              <img src={mini} alt="Kleiner Hund" />
            </div>
            <div className="textwrap">
              <h2>Kleiner Hund</h2>
              <p className="desc">
                Bolonka, Malteser, Parson Russel, Rauhaardackel, Yorkshire
                Terrier
              </p>
            </div>
          </Hund>
          <Hund>
            <div className="imgwrapper">
              <img src={klein} alt="Mittlerer Hund" className="klein" />
            </div>
            <div className="textwrap">
              <h2>Mittlerer Hund</h2>
              <p className="desc">
                Cairn Terrier, Kleinpudel, Zwergschnauzer, West Highland Terrier
              </p>
            </div>
          </Hund>
          <Hund>
            <div className="imgwrapper">
              <img src={mittel} alt="Großer Hund" className="mittel" />
            </div>
            <div className="textwrap">
              <h2>Großer Hund</h2>
              <p className="desc">
                Australian Shepherd, Fox Terrier, Cocker Spaniel,
                Mittelschnauzer, Labradoodle
              </p>
            </div>
          </Hund>
          <Hund>
            <div className="imgwrapper">
              <img src={gross} alt="Sehr Großer Hund" className="gross" />
            </div>
            <div className="textwrap">
              <h2>Sehr Großer Hund</h2>
              <p className="desc">
                Airedale, Berner Sennenhund, Großpudel, Riesenschnauzer
              </p>
            </div>
          </Hund>
        </HundeInside>
      </Hundeliste>
    )}
  />
)

export default Hundegroessen
