import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { mq, colors } from "../utils/presets"
import Leistungspaket from "../components/leistungspaket"
import Hundegroessen from "../components/dogsize"
import Zusatzpakete from "../components/zusatzpakete"
import Button from "../components/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/pro-light-svg-icons"

const Title = styled.h1`
  text-align: center;
  margin: 1rem auto 2rem;
  padding: 1rem 0;
  border-bottom: 3px solid ${colors.black};
  border-top: 3px solid ${colors.black};
  ${mq.xxl} {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
`

const Wrapper = styled.div`
  ${mq.tablet} {
    max-width: 1600px;
    margin: 0 auto;
    margin-bottom: 3rem;
    border-bottom: 3px solid ${colors.black};
    min-height: 33.5rem;
  }
`

const Topimage = styled(Img)`
  ${mq.tablet} {
    margin-left: 2rem;
    float: right;
    width: 50%;
    height: 500px;
    margin-top: 3.8rem;
  }
`
const Textwrapper = styled.div`
  padding: 1rem;
  max-width: 1680px;
  margin: 0 auto;
  ${mq.tablet} {
    /* margin-right: 1rem; */
    h1 {
      text-align: left;
      border-bottom: none;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  /* ${mq.xxl} {
    margin-right: 0 !important;
    padding-right: 0 !important;
  } */
`
const SecondTitle = styled.h2`
  clear: both;
  margin-bottom: 0;
  padding-top: 1rem;
  max-width: 1600px;
  margin: 0 auto;
`

const Priceaddition = styled.p`
  text-align: center;
  margin: 2rem 0;
`

const PageTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressPage.title}
      description={data.wordpressPage.excerpt}
    />
    <Wrapper>
      <Topimage
        objectFit="cover"
        objectPosition="50% 50%"
        sizes={data.wordpressPage.acf.feat_img.localFile.childImageSharp.sizes}
        alt={data.wordpressPage.title}
      />
      <Textwrapper>
        <Title>{data.wordpressPage.title}</Title>
        <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
        {data.wordpressPage.acf.download !== null && (
          <Button
            href={data.wordpressPage.acf.download.url}
            target={data.wordpressPage.acf.download.target}
          >
            <FontAwesomeIcon
              style={{ marginRight: "5" }}
              className="faicon"
              icon={faFilePdf}
            />{" "}
            {data.wordpressPage.acf.download.title}
          </Button>
        )}
      </Textwrapper>
    </Wrapper>
    <Textwrapper>
      <SecondTitle style={{ marginBottom: 0 }}>
        <center>Unsere Preise und Leistungen</center>
      </SecondTitle>
      <Leistungspaket />
    </Textwrapper>

    <Hundegroessen />
    <Textwrapper>
      <SecondTitle style={{ marginBottom: 0 }}>
        <center>Zusatzpakete</center>
      </SecondTitle>
      <Zusatzpakete />
    </Textwrapper>

    <Priceaddition
      style={{ padding: "1rem", maxWidth: "1200px", margin: "0 auto" }}
    >
      Liebe Kunden, bitte haben Sie Verständnis, dass Termine, die nicht
      spätestens 24 Stunden vor Terminbeginn abgesagt wurden, als
      Verdienstausfall vollständig in Rechnung gestellt werden.
      <br />
      <br />
      Aufpreis bei starker Verfilzung und Parasitenbefall.
      <br />
      Alle Preise in EUR inkl. 19% MwSt.
    </Priceaddition>
  </Layout>
)
export default PageTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      acf {
        feat_img {
          localFile {
            childImageSharp {
              sizes(maxWidth: 1200) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
        download {
          title
          url
          target
        }
      }
    }
  }
`
